import type { ParsedUrlQuery } from 'querystring';
import { parse } from 'querystring';

import add from 'date-fns/add';

import { MODES } from '@src/constants/filters';
import { getDynamicParamValue } from '@src/helpers/getDynamicParamValue';
import { Cookie, CookieKey } from '@src/modules/cookie';

import { DEFAULT_AFF_ID_VALUE, affIdByBasePath } from './constants';

export const getAffId = (pathname: string, asPath: string, query: ParsedUrlQuery) => {
  const mode = pathname.slice(1);
  const [basePath, originalQueryString] = asPath.split('?');

  if ([MODES.LANDING, MODES.PARTNER_PAGE, MODES.KRAKEN].includes(mode)) {
    const { partner } = parse(originalQueryString);

    if (partner) {
      return String(partner);
    }
  }

  if (Object.keys(affIdByBasePath).includes(basePath as keyof typeof affIdByBasePath)) {
    return affIdByBasePath[basePath];
  }

  const affIdFromPathname = getDynamicParamValue(asPath, 'affho');

  if (affIdFromPathname) {
    return affIdFromPathname;
  }

  const hasAffIdFromQueryParams = Boolean(query) && Boolean(query.aff_id);

  if (hasAffIdFromQueryParams) {
    Cookie.set(CookieKey.aff_id, query.aff_id.toString(), { expires: add(new Date(), { days: 3 }) });

    return query.aff_id.toString();
  }

  const affIdFromCookie = Cookie.get().aff_id;

  return affIdFromCookie ?? DEFAULT_AFF_ID_VALUE;
};
