import type { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { isWhiteLabelDeniedPage } from '@src/utils/routing';

export const useGetWlPageAffClickId = () => {
  const { asPath, query } = useRouter();

  const isWhiteLabel = isWhiteLabelDeniedPage(asPath);

  return useMemo(() => {
    if (isWhiteLabel && (query.formId || query.dealId)) {
      return getWhiteLabelAffClickId(query);
    }
  }, [isWhiteLabel, query]);
};

function getWhiteLabelAffClickId(query: ParsedUrlQuery): string | undefined {
  let value = '';

  if (query.formId) {
    value = `formId_${query.formId}`;
  }

  if (query.dealId) {
    if (value) {
      value += '|';
    }

    value += `dealId_${query.dealId}`;
  }

  return value;
}
