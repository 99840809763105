interface IParam {
  href: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  features?: 'noopener';
}

export const open = ({ href, target = '_self', features }: IParam): void => {
  const a = document.createElement('a');
  a.href = href;
  a.target = target;

  if (features) {
    a.rel = features;
  }

  a.click();
  a.remove();
};
