export const AB_TEST_TOKEN = 'EC1D9D34-1BA9-4B0F-BD23-94604A5D52FC';

export enum Experiments {
  FULL_DEAL_CONTEXT_ENTRY_BLOCK = '8a2b442a-f52a-4e9c',
  TURBOZAIM = 'cff0ce56',
  OZON_INFO_SCREEN = '39aa495d-2dfb-4a9a',
}

export type AbTestingExperiments = Partial<Record<Experiments, boolean>>;

export const A_TEST_VALUE = '0';
export const B_TEST_VALUE = '1';
export const C_TEST_VALUE = '2';

export const TURBOZAIM_AB_TEST_PIXEL_CLICK = 'https://weaver.sravni.net/api/v1/redirect?aff_id=12216&offer_id=1539';
export const TURBOZAIM_AB_TEST_PIXEL_DISPLAY = 'https://weaver.sravni.net/api/v1/impression?aff_id=12216&offer_id=1539';
