export const getDynamicParamValue = (url: string, paramName: string) => {
  const parts = url.split('/');
  const index = parts.indexOf(paramName);

  const isParamDefined = index !== -1 && index + 1 < parts.length;

  if (isParamDefined) {
    return parts[index + 1];
  }

  return null;
};
